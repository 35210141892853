import axios from "axios"

export const fetchGetUserInfo = async() => {
    try {
        const res = await axios({
            url: "/m/getUserInfo",
            method:"GET"
        })
        return res.data
    } catch(e) {
        return []
    }
}

export const fetchAddUserInfo = async(params) =>{
    try {
        const res = await axios({
            url: "/m/addUserInfo",
            method:"POST",
            data: params
        })
        return res.data
    } catch(e) {
        return []
    }
}

export const fetchDelUserInfo = async(params) =>{
    try {
        const res = await axios({
            url: "/m/delUserInfo",
            method:"POST",
            data: params
        })
        return res.data
    } catch(e) {
        return []
    }
}

export const fetchUpdateUser = async(params) =>{
    try {
        const res = await axios({
            url: "/m/updateUser",
            method:"POST",
            data: params
        })
        return res.data
    } catch(e) {
        return []
    }
}