import { Button, Form, Input, Modal, Space, Table, message } from "antd"
import { fetchGetUserInfo, fetchAddUserInfo, fetchDelUserInfo, fetchUpdateUser } from "../../api/userInfo"
import { useEffect, useState } from "react"

const Home = () => {
    const [dataSource,setDataSource] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState('');

    const [form]  = Form.useForm()

    const getUserInfo = async() => {
        const list = await fetchGetUserInfo()
        setDataSource(list)
    }

    const addUserInfo = async (params) => {
        const res = await fetchAddUserInfo(params);
        if (res.code === '200') {
            message.success("添加成功!")
            setIsOpen(false)
            getUserInfo();
        } else {
            message.error(res.msg);
        }
    }

    const delUserInfo = async(id) => {
        const res = await fetchDelUserInfo({id});
        if (res.code === '200') {
            message.success("删除成功!")
            setIsOpen(false)
            getUserInfo();
        } else {
            message.error(res.msg);
        }
    }

    const updateUser = async(params) => {
        const res = await fetchUpdateUser(params);
        if (res.code === '200') {
            message.success("修改成功!")
            setIsOpen(false)
            getUserInfo();
        } else {
            message.error(res.msg);
        }
    }

    useEffect(()=>{
        getUserInfo();
    },[])

    useEffect(()=>{
        if (!isOpen) {
            form.resetFields();
        }
    },[form, isOpen])

    return <>
        <Space>
            <Button type="primary" onClick={() => {
                setIsOpen(true);
                setModalTitle("添加信息");
            }}>添加</Button>
        </Space>
        <Table 
            tableLayout="fixed" 
            bordered 
            dataSource={dataSource} r
            rowKey={"id"}
        >
            <Table.Column title="标题" dataIndex={"title"} />
            <Table.Column title="内容" dataIndex={"text"} />
            <Table.Column title="地址" dataIndex={"url"} />
            <Table.Column 
                title="操作" 
                width={160}
                render={(record) => <Space>
                    <Button danger type="primary" onClick={() => {
                         Modal.confirm({
                            title: '提示',
                            content: '确认要删除吗？',
                            okText: '确认',
                            cancelText: '取消',
                            onOk: () => {
                                delUserInfo(record.id)
                            }
                          });
                    }}>删除</Button>
                    <Button onClick={() => {
                        setIsOpen(true);
                        setModalTitle("修改信息");
                        form.setFieldsValue(record);
                    }}>修改</Button>
                </Space>} />
        </Table>
        <Modal
            title={modalTitle}
            open={isOpen}
            maskClosable={false}
            forceRender
            onCancel={()=>{
                setIsOpen(false)
            }}
            footer={<>
                <Button onClick={() => {
                    setIsOpen(false);
                }}>取消</Button>
                <Button type="primary" onClick={() => {
                    form.submit();
                }}>确定</Button>
            </>}
        >
            <Form
                form={form}
                labelCol={{flex: '100px'}}
                onFinish={(e) =>{
                    if ((e?.id ?? "") === "") {
                        addUserInfo(e);
                    } else {
                        updateUser(e);
                    }
                }}>
                <Form.Item hidden name={"id"}>
                    <Input autoComplete="off" />
                </Form.Item>
                <Form.Item label="标题" name={"title"} rules={[{required:true, message:"请输入标题！"}]}>
                    <Input autoComplete="off" />
                </Form.Item>
                <Form.Item label="内容" name={"text"}>
                    <Input autoComplete="off" />
                </Form.Item>
                <Form.Item label="地址" name={"url"}>
                    <Input autoComplete="off" />
                </Form.Item>
            </Form>
        </Modal>
    </>
}

export default Home