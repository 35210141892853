import logo from './logo.svg';
import './App.css';
import { FooterView } from "./components"
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom'

function App() {
  const history = useNavigate()

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
       <Button type='primary' onClick={() => {
        history("/home")
       }}>首页</Button>
      </header>
      <FooterView />
    </div>
  );
}

export default App;
