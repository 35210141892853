import { Component } from "react";
import style from "./FooterScss.module.css"

class FooterView extends Component {
    render() {
        return <div className={style["footer-box"]}>
            <a href="https://beian.miit.gov.cn/" target="_blank" rel="noreferrer">晋ICP备2020014941号-1</a>
        </div>
    }
}

export default FooterView