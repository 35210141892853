import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from '../App';
import Home from "../pages/Home"

const Router = () => {
    return <BrowserRouter>
        <Routes>
            <Route path="/" element={<App/>}></Route>
            <Route path="/home" element={<Home />}></Route>
        </Routes>
    </BrowserRouter>
}

export default Router